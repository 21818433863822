import qlik from './qlik';
import {dimensions, measures} from './qlik/klant/klant';
import {dimensions as bos_dimensions, measures as bos_measures} from './qlik/klant/bos';
import {measures as co2measures} from './qlik/co2';


export default [
    {
      value: 'dimensions',
      label: 'Dimensies',
      class: 'bg-primary',
      children: [
        {
          label: 'Debiteur',
          value: 'debiteur',
          children: [
            {
              value: "dimensions.debtor.name",
              label: 'Naam',
              qlik: dimensions.debtor.name,
            },
            {
              value: "dimensions.debtor.number",
              label: 'Nummer',
              qlik: dimensions.debtor.number
            }
          ]
        },
        {
          label: 'Opdrachtgever',
          value: 'Opdrachtgever',
          children: [
            {
              value: "dimensions.customer.name",
              label: 'Naam',
              qlik: dimensions.customer.name,
            },
            {
              value: "dimensions.customer.number",
              label: 'Nummer',
              qlik: dimensions.customer.number
            }
          ]
        },
        {
          label: 'Order',
          value: 'order',
          children: [
            {
              value: "dimensions.order.number",
              qlik: dimensions.order.number,
              label: 'Ordernummer'
            },
            {
              value: "dimensions.order.kind",
              qlik: dimensions.order.kind,
              label: 'Ordersoort'
            },
            {
              value: "dimensions.order.reference",
              qlik: dimensions.order.reference,
              label: 'Referentie'
            },
            {
              value: "bos_dimensions.order.zendinggroottegroep",
              qlik: bos_dimensions.order.zendinggroottegroep,
              label: 'Groep zendinggrootte'
            },
          ]
        },
        {
          label: 'Bedrijf',
          value: 'Bedrijf',
          children: [
            {
              value: "bos_dimensions.order.companyorderrecievednr",
              qlik: bos_dimensions.order.companyorderrecievednr,
              label: 'Code [Order ontvangen]'
            },
            {
              value: "bos_dimensions.order.companyorderrecievedomschr",
              qlik: bos_dimensions.order.companyorderrecievedomschr,
              label: 'Omschrijving [Order ontvangen]'
            },
            {
              value: "bos_dimensions.order.companyorderexcecutednr",
              qlik: bos_dimensions.order.companyorderexcecutednr,
              label: 'Code [Order uitgevoerd]'
            },
            {
              value: "bos_dimensions.order.companyorderexcecutedomschr",
              qlik: bos_dimensions.order.companyorderexcecutedomschr,
              label: 'Omschrijving [Order uitgevoerd]'
            },
          ]
        },
        {
          label: 'Laadlocatie',
          value: 'laadlocatie',
          children: [
            {
              value: "dimensions.loadinglocation.adress",
              qlik: dimensions.loadinglocation.adress,
              label: 'Adres'
            },
            {
              value: "dimensions.loadinglocation.zipcode",
              qlik: dimensions.loadinglocation.zipcode,
              label: 'Postcode'
            },
            {
              value: "dimensions.loadinglocation.city",
              qlik: dimensions.loadinglocation.city,
              label: 'Plaats'
            },
            {
              value: "dimensions.loadinglocation.district",
              qlik: dimensions.loadinglocation.district,
              label: 'Gemeente'
            },
            {
              value: "dimensions.loadinglocation.province",
              qlik: dimensions.loadinglocation.province,
              label: 'Provincie'
            },
            {
              value: "dimensions.loadinglocation.land",
              qlik: dimensions.loadinglocation.land,
              label: 'Land'
            },
            {
              value: "dimensions.loadinglocation.country",
              qlik: dimensions.loadinglocation.country,
              label: 'Land (ISO)'
            },
          ]
        },
        {
          label: 'Loslocatie',
          value: 'loslocatie',
          children: [
            {
              value: "dimensions.unloadinglocation.adress",
              qlik: dimensions.unloadinglocation.adress,
              label: 'Adres'
            },
            {
              value: "dimensions.unloadinglocation.zipcode",
              qlik: dimensions.unloadinglocation.zipcode,
              label: 'Postcode'
            },
            {
              value: "dimensions.unloadinglocation.city",
              qlik: dimensions.unloadinglocation.city,
              label: 'Plaats'
            },
            {
              value: "dimensions.unloadinglocation.district",
              qlik: dimensions.unloadinglocation.district,
              label: 'Gemeente'
            },
            {
              value: "dimensions.unloadinglocation.province",
              qlik: dimensions.unloadinglocation.province,
              label: 'Provincie'
            },
            {
              value: "dimensions.unloadinglocation.country",
              qlik: dimensions.unloadinglocation.country,
              label: 'Land'
            },
            {
              value: "dimensions.unloadinglocation.land",
              qlik: dimensions.unloadinglocation.land,
              label: 'Land (ISO)'
            },
          ]
        },
        {
          label: 'Periode',
          value: 'periode',
          children: [
            {
              value: "dimensions.period.year",
              qlik: dimensions.period.year,
              label: 'Jaar'
            },
            {
              value: "dimensions.period.quarter",
              qlik: dimensions.period.quarter,
              label: 'Kwartaal'
            },
            {
              value: "dimensions.period.month",
              qlik: dimensions.period.month,
              label: 'Maand'
            },
            {
              value: "dimensions.period.week",
              qlik: dimensions.period.week,
              label: 'Week'
            },
            {
              value: "dimensions.period.date",
              qlik: dimensions.period.date,
              label: 'Datum'
            },
            {
              value: "dimensions.period.weekday",
              qlik: dimensions.period.weekday,
              label: 'Dag'
            },
          ]
        }
      ]
    },
    {
      label: 'Meetwaarden',
      value: 'measures',
      class: 'bg-danger',
      children: [
        {
          value: 'omzet',
          label: 'Omzet',
          children: [
            {
              value: "measures.omzet.totaal",
              qlik: measures.omzet.totaal,
              label: 'Totaal'
            },
            {
              value: "measures.omzet.freight",
              qlik: measures.omzet.freight,
              label: 'Vracht'
            },
            {
              value: "measures.omzet.fuel",
              qlik: measures.omzet.fuel,
              label: 'Brandstof'
            },
            {
              value: "measures.omzet.other",
              qlik: measures.omzet.other,
              label: 'Overig'
            },
          ]
        },
        {
          value: 'kosten',
          label: 'Kosten',
          children: [
            {
              value: "measures.kosten.totaal",
              qlik: measures.kosten.totaal,
              label: 'Totaal',
              help: 'Totaal van de directe kosten.'
            },
            {
              value: "measures.kosten.charter",
              qlik: measures.kosten.charter,
              label: 'Charter', 
              help: 'Kosten van kilometer, uren en vaste charters'
            },
            {
              value: "measures.kosten.toll",
              qlik: measures.kosten.toll,
              label: 'Tol',
              help: 'Tolkosten worden berekend op basis van tolkilometers uit Winsped wanneer deze berekend zijn voor een eigen auto'
            },
            {
              value: "measures.kosten.warehouse",
              qlik: measures.kosten.warehouse,
              label: 'Warehouse',
              help: 'Gecalculeerde kosten berekend voor de reservering van de directe Warehouse kosten per zending (op basis van een percentage van de omzet)'
            },
            {
              value: "measures.kosten.collect",
              qlik: measures.kosten.collect,
              label: 'Voorhaal',
              help: 'Gecalculeerde voorhaalkosten op basis van tarief x kg voor aangegeven trajecten'
            },
            {
              value: "measures.kosten.other",
              qlik: measures.kosten.other,
               label: 'Other',
               help: 'Alle kosten die niet in één van bovenstaande categoriën ingedeeld zijn vallen hieronder'
            },
          ]
        },
        {
          value: 'aantal',
          label: 'Aantal',
          children: [
            {
              value: "measures.aantal.orders",
              qlik: measures.aantal.orders,
              label: 'Zendingen',
              help: 'Het aantal zendingen, waarbij een zending een unieke transportopdracht is van A naar B'
            },
            {
              value: "measures.aantal.referenties",
              qlik: bos_measures.aantal.referenties,
              label: 'Referenties',
              help: 'Het aantal referenties. Telt het aantal uitgevoerde ritten van Unilin en Knauff.'
            },
            {
              value: "measures.aantal.ritten",
              qlik: measures.aantal.ritten,
              label: 'Ritten',
              help: 'Het aantal ritten behorend bij de geselecteerde zendingen. Hier zien we altijd het totaal. '
            },
          ]
        },
        {
          value: 'kpi',
          label: 'KPI',
          children: [
            {
              value: "measures.kpi.goedkeur",
              qlik: measures.kpi.goedkeur,
              label: 'OK',
              help: 'Het percentage zendingen dat goedgekeurd is. Wanneer er iets misgaat met een zending wordt deze niet meegenomen in de kerngetallen.'
            },
  
            {
              value: "measures.kpi.normmarginpercent",
              qlik: measures.kpi.normmarginpercent,
              label: 'Norm Marge %',
              help: 'De Norm Marge combineert de charter marge en de omzet/uur om deze samen te toetsen tegen de gestelde normen. Voor het eigen werk wordt gekeken hoeveel de omzet / uur afwijkt van de norm voor de omzet/uur. Dit wordt gecombineerd met de afwijking van de chartermarge ten opzichte van de norm voor de chartermarge. Dit wordt enkel uitgerekend voor goedgekeurde orders.'
            },
            {
              value: "measures.kpi.normmarginomzet",
              qlik: measures.kpi.normmarginomzet,
              label: 'Norm Marge',
              help: 'De Norm Marge interpoleert het berekende percentage over de gehele omzet.'
            },
            {
              value: "bos_measures.kpi.omzetperzending",
              qlik: bos_measures.kpi.omzetperzending,
              label: 'Omzet per zending',
              help: 'Omzet / zending.'
            },
          ]
        },
        {
          value: 'eigenwagen',
          label: 'Eigen Wagen',
          children: [
            {
              value: "measures.own.omzetperuur",
              qlik: measures.own.omzetperuur,
              label: 'Omzet / Uur', 
              help: 'De omzet / uur is de omzet van het eigen werk minus de DOT en minus de directe-kosten (Tol, X-dock) van het eigen werk afgezet tegen het aantal ingezette uren. Het gaat hier altijd enkel om goedgekeurde orders. '
            },
            // {
            //   value: "measures.own.omzetperuurexfuel",
            //   qlik: measures.own.omzetperuurexfuel,
            //   label: 'Omzet / Uur zonder DOT', 
            //   help: 'De omzet / uur zonder DOT is de omzet van het eigen werk minus de DOT en minus de directe-kosten (Tol, X-dock) van het eigen werk afgezet tegen het aantal ingezette uren. Het gaat hier altijd enkel om goedgekeurde orders. '
            // },
            {
              value: "measures.own.omzetperuurinclfuel",
              qlik: measures.own.omzetperuurinclfuel,
              label: 'Omzet / Uur incl DOT', 
              help: 'De omzet / uur incl DOT is de volledige omzet van het eigen werk minus de directe-kosten (Tol, X-dock) van het eigen werk afgezet tegen het aantal ingezette uren. Het gaat hier altijd enkel om goedgekeurde orders. '
            },
            {
              value: "measures.own.tripomzetperuur",
              qlik: measures.own.tripomzetperuur,
              label: 'Omzet / Uur - rit',
              help: 'De omzet / uur van de gehele rit(ten) waar deze order(s) ingezeten heeft/hebben. Het gaat hier altijd enkel om goedgekeurde ritten. '
            },
            {
              value: "measures.own.tripomzetperuurinclfuel",
              qlik: measures.own.tripomzetperuurinclfuel,
              label: 'Omzet / Uur - rit incl DOT',
              help: 'De omzet / uur van de gehele rit(ten) waar deze order(s) ingezeten heeft/hebben. Het gaat hier altijd enkel om goedgekeurde ritten. '
            },
            // {
            //   value: "measures.own.tripomzetperuurexfuel",
            //   qlik: measures.own.tripomzetperuurexfuel,
            //   label: 'Omzet / Uur - rit zonder DOT',
            //   help: 'De omzet / uur van de gehele rit(ten) waar deze order(s) ingezeten heeft/hebben. Het gaat hier altijd enkel om goedgekeurde ritten. '
            // },
            {
              value: "measures.own.tripomzetperuurvolgenderit",
              qlik: measures.own.omzetperuurvolgenderit,
              label: 'Omzet / Uur - rondrit',
              help: 'De omzet / uur van de gehele rondrit(ten) waar deze order(s) ingezeten heeft/hebben. Deze meetwaarde wordt alleen getoond als de rit + volgende rit beiden goedgekeurd zijn. '
            },
            {
              value: "measures.own.normomzetperuur",
              qlik: measures.own.normomzetperuur,
              label: 'Norm Omzet / Uur',
              help: 'De geconfigureerde norm op orderniveau  voor het eigen werk. De omzet per uur wordt getoetst aan deze norm. '

            },
          ]
        },
        {
          value: 'uitbesteed',
          label: 'Uitbesteed',
          children: [
            {
              value: 'measures.outsourced.percentage',
              label: '% Uitbesteed',
              qlik: measures.outsourced.percentage,
              help: 'Het percentage dat is uitbesteed. Indien een zending in het voorhaaltraject door een eigen wagen uitgevoerd wordt en het wegbrengen wordt uitbesteed is deze zending voor 50% uitbesteed.'
            },
            {
              value: 'measures.outsourced.marge',
              label: 'Derden Marge',
              qlik: measures.outsourced.marge,
              help: 'De marge op het uitbesteede werk. Dit is de omzet minus de kosten. Hierbij wordt enkel de omzet en de kosten meegenomen indien de order goedgekeurd is. '
            },
            {
              value: 'measures.outsourced.norm',
              label: 'Norm',
              qlik: measures.outsourced.norm,
              help: 'De geconfigureerde norm op orderniveau  voor het uitbesteede werk De Derden Marge wordt getoetst aan deze norm. '
            },
          ]
        },
        {
          value: 'potency',
          label: 'Potentie',
          children: [
            {
              value: 'measures.potentie.totaal',
              label: 'Totaal',
              qlik: measures.potentie.totaal
            },
            {
              value: 'measures.potentie.commercieel',
              label: 'Commercieel',
              qlik: measures.potentie.commercieel
            },
            {
              value: 'measures.potentie.operationeel',
              label: 'Operationeel',
              qlik: measures.potentie.operationeel
            },
          ]
        },
        {
          value: 'km',
          label: 'KM',
          children: [
            {
              value: 'measures.km.totaal',
              label: 'Totaal',
              qlik: measures.km.km,
              help: 'Geeft de totale kilometers op orderniveau weer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'measures.km.omzetperkm',
              label: 'Omzet / KM',
              qlik: measures.km.omzetperkm,
              help: 'Geeft de omzet per kilometer weer. Hierbij wordt enkel de goedgekeurde omzet afgezet tegen de goedgekeurde kilometers'
            },
            {
              value: 'measures.km.normomzetperkm',
              label: 'Norm Omzet / KM',
              qlik: measures.km.normomzetperkm,
              help: 'De geconfigureerde norm op orderniveau voor de Omzet / KM.'
            },
            {
              value: 'bos_measures.km.gecalculeerd',
              label: 'Gecalculeerd',
              qlik: bos_measures.km.gecalculeerd,
              help: 'De rechtstreekse (ATCF) afstand van de order.'
            }
          ]
        },
        {
          value: 'uren',
          label: 'Uren',
          children: [
            {
              value: 'measures.uren.totaal',
              label: 'Totaal (Σ)',
              qlik: measures.uren.totaal,
              help: 'Het totaal aantal uren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'measures.uren.loading',
              label: 'Laden (Σ)',
              qlik: measures.uren.loading,
              help: 'Het totaal aantal laaduren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'measures.uren.unloading',
              label: 'Lossen (Σ)',
              qlik: measures.uren.unloading, 
              help: 'Het totaal aantal losuren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '

            },
            {
              value: 'measures.uren.driving',
              label: 'Rijden (Σ)',
              qlik: measures.uren.driving,
              help: 'Het totaal aantal rijuren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '

            },
            {
              value: 'measures.uren.other',
              label: 'Overig (Σ)',
              qlik: measures.uren.other,
              help: 'Het totaal aantal overige uren op orderniveau uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'measures.uren.avg_totaal',
              label: 'Totaal (avg)',
              qlik: measures.uren.avg_totaal,
              help: 'Het gemiddeld aantal uren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'measures.uren.avg_loading',
              label: 'Laden (avg)',
              qlik: measures.uren.avg_loading,
              help: 'Het gemiddeld aantal laaduren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'measures.uren.avg_unloading',
              label: 'Lossen (avg)',
              qlik: measures.uren.avg_unloading,
              help: 'Het gemiddeld aantal losuren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'measures.uren.avg_driving',
              label: 'Rijden (avg)',
              qlik: measures.uren.avg_driving,
              help: 'Het gemiddeld aantal rijuren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            },
            {
              value: 'measures.uren.avg_other',
              label: 'Overig (avg)',
              qlik: measures.uren.avg_other,
              help: 'Het gemiddeld aantal overige uren per order uitgevoerd door eigen wagens en teruggekoppeld vanuit de boordcomputer. Hierbij wordt rekening gehouden met de instelling in het filterblad. '
            }
          ]
        },
        {
          value: 'persize',
          label: 'Per Eenheid',
          children: [
            {
              value: 'lm',
              label: 'LM',
              children: [
                {
                  value: 'measures.persize.lm',
                  label: 'Totaal',
                  qlik: measures.persize.lm,
                  help: 'Het totaal aantal laadmeters vanuit Winsped'
                },
                {
                  value: 'measures.persize.avg_lm',
                  label: 'Gemiddelde',
                  qlik: measures.persize.avg_lm,
                  help: 'Het gemiddelde aantal laadmeters per zending vanuit Winsped',
                },
              ]
            },
            {
              value: 'kg',
              label: 'KG',
              children: [
                {
                  value: 'measures.persize.kg',
                  label: 'Totaal',
                  qlik: measures.persize.kg,
                  help: 'Het totaal aantal kilo\'s vanuit Winsped'

                },
                {
                  value: 'measures.persize.avg_kg',
                  label: 'Gemiddelde',
                  qlik: measures.persize.avg_kg,
                  help: 'Het gemiddelde aantal kilo\'s vanuit Winsped'

                }
              ]
            },
            
            {
              value: 'M3',
              label: 'M3',
              children: [
                {
                  value: 'measures.persize.m3',
                  label: 'Totaal',
                  qlik: measures.persize.m3,
                  help: 'Het totaal aantal m3 vanuit Winsped'

                },
                {
                  value: 'measures.persize.avg_m3',
                  label: 'Gemiddelde',
                  qlik: measures.persize.avg_m3,
                  help: 'Het gemiddelde aantal m3 vanuit Winsped'

                }
              ]
            },
            {
              value: 'zendinggrootte',
              label: 'Berekende KG',
              children: [
                {
                  value: 'measures.persize.shipmentsize',
                  label: 'Totaal',
                  qlik: measures.persize.shipmentsize,
                  help: 'Het totaal aantal berekende KG. Waarbij M3 / KG / PP is omgerekend naar KG.'
                },
                {
                  value: 'measures.persize.omzet_shipmentsize',
                  label: 'Omzet / LM',
                  qlik: measures.persize.omzet_shipmentsize,
                  help: 'De totale omzet / gedeeld door het totale aantal berekende LM.'
                },
                {
                  value: 'measures.persize.loading_shipmentsize',
                  label: 'Laden / LM',
                  qlik: measures.persize.loading_shipmentsize,
                  help: 'Het aantal laadminuten per berekende laadmeter. Hierbij worden enkel de goedgekeurde orders meegenomen.'
                },
                {
                  value: 'measures.persize.unloading_shipmentsize',
                  label: 'Lossen / LM',
                  qlik: measures.persize.unloading_shipmentsize,
                  help: 'Het aantal losminuten per berekende laadmeter. Hierbij worden enkel de goedgekeurde orders meegenomen.'
                },
                {
                  value: 'measures.persize.time_shipmentsize',
                  label: 'Tijd / LM',
                  qlik: measures.persize.time_shipmentsize,
                  help: 'Het aantal minuten per berekende laadmeter. Hierbij worden enkel de goedgekeurde orders meegenomen.'
                },
              ]
            },
          ]
        },
        // {
        //   value: 'CO2',
        //   label: 'CO2',
        //   children: [
        //     {
        //       qlik: co2measures.co2.rekenkilometers,
        //       value: 'vE_OrderCo2KM',
        //       label: 'Rekenkilometers',
        //       help: 'De kilometers voor de CO2, indien niet aanwezig worden zijn deze geinterpoleerd op basis van de afstand en zendinggrootte'
        //     },
        //     {
        //       qlik: co2measures.co2.verbruikperkm,
        //       value: 'vE_OrderCo2Verbruik',
        //       label: 'Vebruik per km',
        //       help: 'Het brandstofverbruik per kilometer, op dit moment een vaste norm vastgesteld op 0.3 liter per km'
        //     },
        //     {
        //       qlik: co2measures.co2.litersbrandstof,
        //       value: 'vE_OrderCo2L',
        //       label: 'Liters Brandstof',
        //       help: 'Het aantal liters brandstof op basis van het aantal rekenkilometers in combinatie met het brandstofverbruik'
        //     },
        //     {
        //       qlik: co2measures.co2.uitstootfactorwtw,
        //       value: 'vE_TripCo2FactorWTW',
        //       label: 'Uitstoot factor WTW',
        //       help: 'De factor voor de uitstoot van een liter brandstof WellToWheel (WTW)'
        //     },
        //     {
        //       qlik: co2measures.co2.uitstootfactorttw,
        //       value: 'vE_TripCo2FactorTTW',
        //       label: 'Uitstoot factor TTW',
        //       help: 'De factor voor de uitstoot van een liter brandstof TankToWheel (TTW)'
        //     },
        //     {
        //       qlik: co2measures.co2.co2uitstootwtw,
        //       value: 'vE_TripCo2TotaalWTW',
        //       label: 'CO2 uitstoot (WTW)',
        //     },
        //     {
        //       qlik: co2measures.co2.co2uitstootttw,
        //       value: 'vE_TripCo2TotaalTTW',
        //       label: 'CO2 uitstoot (TTW)',
        //     }
        //   ]
        // },
      ]
    }
  ];
  